<template>
  <section class="main-bg m-mh">
    <navi-bar>
      {{$t('front.stributor.winlose')}}
    </navi-bar>

    <date-filter-mobile :add="'3month'" @search="loadList"
                        @update="onChangeDate"
                        :startDate="searchDate.startDate"
                        :endDate="searchDate.endDate" />

    <ul class="calcmoney">
      <li>{{$t('front.stributor.progressMoney')}}:<span class="yw">2,520,000</span></li>
      <li>{{$t('front.stributor.allOfMoney')}}:<span class="yw">500,000</span></li>
    </ul>

    <div class="gamebetwrap">

      <template v-if="list.length > 0">
        <template v-for="(item, index) in list" v-bind:key="pageInfo.page * index">
          <div class="gamebetlist">
            <input type="checkbox">
            <ul class="gamebetdetail w100-100">
              <li><span>정산시작일</span>:{{item.startDate}}</li>
              <li><span>정산종료일</span>:{{item.endDate}}</li>
              <li><span>분류</span>:{{item.gameName}}</li>
              <li><span>금액</span>
                <template v-if="item.paystatus === 'COMP'">
                  <template v-if="item.summaryAmtPaid > 0">
                    <span class="rd">+{{thousand(item.summaryAmtPaid)}}</span>
                  </template>
                  <template v-else>
                    <span class="bbl">{{thousand(item.summaryAmtPaid)}}</span>
                  </template>
                </template>
                <template v-else>
                  {{thousand(item.summaryAmtBalance)}}
                </template>
              </li>

            </ul>
            <template v-if="item.paystatus === 'COMP'">
              <a class="done">정산<br />완료</a>
            </template>
            <template v-else>
              <a class="cancel">진행중</a>
            </template>
          </div>
        </template>
      </template>
      <template v-else>

      </template>
    </div>

    <ul class="calcmoneytotal">
      <li>합계</li>
      <li class="rd" v-if="listSum > 0">+{{thousand(listSum)}}</li>
      <li v-else-if="listSum === 0">0</li>
      <li class="bbl" v-else>{{thousand(listSum)}}</li>
    </ul>

    <div class="btnwrap">
      <a class="all" click="onAllCheck">{{allChecked ? '전체해제': '전체선택'}}</a>
      <a class="selec" @click="onRemoveSelect">선택삭제</a>
    </div>

    <pagination-mobile :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList"/>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import winlose from '@/views/member/mypage/winlose'

export default {
  name: 'winlose.vue',
  mixins: [winlose],
  components: {
    NaviBar,
    DateFilterMobile,
    PaginationMobile
  },
  data () {
    return {
      type: 'apply'
    }
  }
}
</script>

<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/betlist.css"></style>
